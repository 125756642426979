.section1 {
  background-image: linear-gradient(to bottom, #fff, #f5eedd);
  overflow: hidden;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 0.7rem;
  padding-bottom: 1.9rem;
}

@media (max-width: 1600px) {
  .section1 {
    padding-left: 9%;
  }
}

@media (max-width: 1366px) {
  .section1 {
    padding-left: 8%;
  }
}

@media (max-width: 1024px) {
  .section1 {
    padding-left: 7%;
  }
}

@media (max-width: 991px) {
  .section1 {
    padding-left: 6%;
  }
}

@media (max-width: 767px) {
  .section1 {
    padding-left: 5%;
  }
}

@media (max-width: 1600px) {
  .section1 {
    padding-right: 9%;
  }
}

@media (max-width: 1366px) {
  .section1 {
    padding-right: 8%;
  }
}

@media (max-width: 1024px) {
  .section1 {
    padding-right: 7%;
  }
}

@media (max-width: 991px) {
  .section1 {
    padding-right: 6%;
  }
}

@media (max-width: 767px) {
  .section1 {
    padding-right: 5%;
  }
}

@media (max-width: 1024px) {
  .section1 {
    padding-top: 1.2rem;
  }
}

@media (max-width: 767px) {
  .section1 {
    padding-top: 0.7rem;
  }
}

@media (max-width: 767px) {
  .section1 {
    padding-bottom: 1rem;
  }
}

.section1 .t-img {
  text-align: center;
  white-space: nowrap;
  font-size: 1.6rem;
}

@media (max-width: 767px) {
  .section1 .t-img {
    font-size: 1.2rem;
  }
}

.section1 .t-img .img {
  overflow: hidden;
}

.section1 .t-img .img.is-inview img {
  transform: translateY(0);
}

.section1 .t-img .img img {
  display: block;
  max-width: 90%;
  height: auto;
  transform: translateY(100%);
  margin: 0 auto;
  width: 7rem;
}

.section1 .t-img .t {
  overflow: hidden;
  color: #efe3c6;
  font-weight: lighter;
  margin-bottom: 0.2rem;
  font-size: 0.28rem;
}

@media (max-width: 1024px) {
  .section1 .t-img .t {
    font-weight: normal;
  }
}

@media (max-width: 767px) {
  .section1 .t-img .t {
    font-size: 0.34rem;
  }
}

.section1 .t-img .t.is-inview span {
  transform: translateY(0);
}

.section1 .t-img .t span {
  display: block;
  transform: translateY(100%);
}

.section1 .video-c {
  overflow: hidden;
  position: relative;
}

.section1 .video-c .video {
  width: 100%;
  height: auto;
  background-position: center;
  background-size: cover;
}

.section1 .video-c .play {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid rgba(255, 255, 255, 0.3);
  position: absolute;
  z-index: 2;
  transition: all .5s;
  cursor: pointer;
  z-index: 2;
  top: calc(50% - 1.6rem);
  left: calc(50% - 1.6rem);
  border-width: 0.1rem;
  width: 3.2rem;
  height: 3.2rem;
}

@media (max-width: 767px) {
  .section1 .video-c .play {
    top: calc(50% - .7rem);
  }
}

@media (max-width: 767px) {
  .section1 .video-c .play {
    left: calc(50% - .7rem);
  }
}

@media (max-width: 767px) {
  .section1 .video-c .play {
    border-width: 0.05rem;
  }
}

@media (max-width: 767px) {
  .section1 .video-c .play {
    width: 1.4rem;
  }
}

@media (max-width: 767px) {
  .section1 .video-c .play {
    height: 1.4rem;
  }
}

.section1 .video-c .play .iconfont {
  line-height: 1;
  color: rgba(255, 255, 255, 0.3);
  font-size: 2rem;
}

@media (max-width: 767px) {
  .section1 .video-c .play .iconfont {
    font-size: 0.8rem;
  }
}

.section1 .video-c .play.fadeOut {
  opacity: 0;
  visibility: hidden;
}

.section2 {
  background: #f5eedd;
  padding-left: 11.2%;
}

@media (max-width: 1600px) {
  .section2 {
    padding-left: 9.96%;
  }
}

@media (max-width: 1366px) {
  .section2 {
    padding-left: 8.72%;
  }
}

@media (max-width: 1024px) {
  .section2 {
    padding-left: 7.48%;
  }
}

@media (max-width: 991px) {
  .section2 {
    padding-left: 6.24%;
  }
}

@media (max-width: 767px) {
  .section2 {
    padding-left: 5%;
  }
}

.section2 .cont1 {
  margin-bottom: 0.6rem;
}

.section2 .cont1 h2 {
  line-height: 1.3;
  font-size: 0.57rem;
  margin-bottom: 0.3rem;
}

@media (max-width: 767px) {
  .section2 .cont1 h2 {
    font-size: 0.44rem;
  }
}

.section2 .cont1 h2 > div:nth-child(2) span {
  transition-delay: .5s;
}

.section2 .cont1 h2 + div span {
  transition-delay: 1s;
  font-size: 0.4rem;
}

@media (max-width: 767px) {
  .section2 .cont1 h2 + div span {
    font-size: 0.4rem;
  }
}

.section2 .white-bg {
  background-color: #fff;
  padding-right: 9.7%;
  padding-bottom: 1.8rem;
}

@media (max-width: 1600px) {
  .section2 .white-bg {
    padding-right: 8.76%;
  }
}

@media (max-width: 1366px) {
  .section2 .white-bg {
    padding-right: 7.82%;
  }
}

@media (max-width: 1024px) {
  .section2 .white-bg {
    padding-right: 6.88%;
  }
}

@media (max-width: 991px) {
  .section2 .white-bg {
    padding-right: 5.94%;
  }
}

@media (max-width: 767px) {
  .section2 .white-bg {
    padding-right: 5%;
  }
}

@media (max-width: 767px) {
  .section2 .white-bg {
    padding-bottom: 0rem;
  }
}

.section2 .white-bg .cont2 {
  align-items: flex-start;
  justify-content: space-between;
  display: flex;
}

@media (max-width: 767px) {
  .section2 .white-bg .cont2 {
    display: block;
  }
}

.section2 .white-bg .cont2 .left {
  flex-shrink: 0;
  box-sizing: border-box;
  padding-top: 1.2rem;
  padding-left: 0.9rem;
  width: 42%;
}

@media (max-width: 767px) {
  .section2 .white-bg .cont2 .left {
    padding-top: 0.6rem;
  }
}

@media (max-width: 767px) {
  .section2 .white-bg .cont2 .left {
    padding-left: 0.3rem;
  }
}

@media (max-width: 767px) {
  .section2 .white-bg .cont2 .left {
    width: 100%;
  }
}

.section2 .white-bg .cont2 .left .t {
  line-height: 2;
}

.section2 .white-bg .cont2 .left .t p {
  margin-bottom: 0.4rem;
}

.section2 .white-bg .cont2 .left .t-img {
  position: relative;
  margin-top: 1rem;
  width: 3.06rem;
}

.section2 .white-bg .cont2 .left .t-img .img1 {
  display: block;
  height: auto;
  width: 100%;
}

.section2 .white-bg .cont2 .left .t-img .link {
  position: absolute;
  left: 100%;
  bottom: 0;
}

.section2 .white-bg .cont2 .left .t-img .link .flourish {
  white-space: nowrap;
  text-shadow: none;
  display: block;
  font-size: 0.38rem;
  margin-bottom: 0.25rem;
}

@media (max-width: 767px) {
  .section2 .white-bg .cont2 .left .t-img .link .flourish {
    font-size: 0.44rem;
  }
}

.section2 .white-bg .cont2 .right {
  flex-shrink: 0;
  background-color: #f5eedd;
  position: relative;
  z-index: 1;
  margin-top: -10%;
  padding-bottom: 1.2rem;
  width: 44%;
}

@media (max-width: 767px) {
  .section2 .white-bg .cont2 .right {
    margin-top: 1.2rem;
  }
}

@media (max-width: 767px) {
  .section2 .white-bg .cont2 .right {
    padding-bottom: 0.8rem;
  }
}

@media (max-width: 767px) {
  .section2 .white-bg .cont2 .right {
    width: 80%;
  }
}

.section2 .white-bg .cont2 .right::after {
  content: '';
  width: 200%;
  height: 100%;
  background-color: #f5eedd;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  display: none;
}

@media (max-width: 767px) {
  .section2 .white-bg .cont2 .right::after {
    display: block;
  }
}

.section2 .white-bg .cont2 .right .img1 {
  display: block;
  width: 100%;
  height: auto;
  margin-top: -10%;
  margin-left: 30%;
}

.section2 .white-bg .cont2 .right .img2 {
  display: block;
  width: 90%;
  height: auto;
  position: absolute;
  top: 8%;
  left: -20%;
}

@media (max-width: 767px) {
  .section2 .white-bg .cont2 .right .img2 {
    top: 12%;
  }
}

@media (max-width: 767px) {
  .section2 .white-bg .cont2 .right .img2 {
    left: 0;
  }
}

.section2 .white-bg .cont3 {
  position: relative;
  z-index: 1;
  margin-top: 1.5rem;
  padding-top: 0;
  padding-bottom: 1.2rem;
  padding-left: 10%;
}

@media (max-width: 767px) {
  .section2 .white-bg .cont3 {
    margin-top: 0rem;
  }
}

@media (max-width: 767px) {
  .section2 .white-bg .cont3 {
    padding-top: 0.5rem;
  }
}

@media (max-width: 767px) {
  .section2 .white-bg .cont3 {
    padding-bottom: 1rem;
  }
}

@media (max-width: 1600px) {
  .section2 .white-bg .cont3 {
    padding-left: 9%;
  }
}

@media (max-width: 1366px) {
  .section2 .white-bg .cont3 {
    padding-left: 8%;
  }
}

@media (max-width: 1024px) {
  .section2 .white-bg .cont3 {
    padding-left: 7%;
  }
}

@media (max-width: 991px) {
  .section2 .white-bg .cont3 {
    padding-left: 6%;
  }
}

@media (max-width: 767px) {
  .section2 .white-bg .cont3 {
    padding-left: 5%;
  }
}

.section2 .white-bg .cont3::after {
  content: '';
  width: 200%;
  height: 100%;
  background-color: #f5eedd;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  display: none;
}

@media (max-width: 767px) {
  .section2 .white-bg .cont3::after {
    display: block;
  }
}

.section2 .white-bg .cont3 .pic_1 {
  padding-right: 15%;
}

@media (max-width: 767px) {
  .section2 .white-bg .cont3 .pic_1 {
    width: 110%;
  }
}

@media (max-width: 767px) {
  .section2 .white-bg .cont3 .pic_1 {
    margin-left: -5%;
  }
}

@media (max-width: 1600px) {
  .section2 .white-bg .cont3 .pic_1 {
    padding-right: 12%;
  }
}

@media (max-width: 1366px) {
  .section2 .white-bg .cont3 .pic_1 {
    padding-right: 9%;
  }
}

@media (max-width: 1024px) {
  .section2 .white-bg .cont3 .pic_1 {
    padding-right: 6%;
  }
}

@media (max-width: 991px) {
  .section2 .white-bg .cont3 .pic_1 {
    padding-right: 3%;
  }
}

@media (max-width: 767px) {
  .section2 .white-bg .cont3 .pic_1 {
    padding-right: 0%;
  }
}

.section2 .white-bg .cont3 .pic_1 img {
  display: block;
  width: 100%;
  height: auto;
}

.section2 .white-bg .cont3 .text {
  position: relative;
  margin-top: -1rem;
}

.section2 .white-bg .cont3 .text .common-tag {
  margin-left: -0.6rem;
}

@media (max-width: 767px) {
  .section2 .white-bg .cont3 .text .common-tag {
    margin-left: 0;
  }
}

.section2 .white-bg .cont3 .text .text-img {
  position: absolute;
  right: 0;
  top: -20%;
  font-size: 1.28rem;
}

@media (max-width: 767px) {
  .section2 .white-bg .cont3 .text .text-img {
    top: -80%;
  }
}

@media (max-width: 767px) {
  .section2 .white-bg .cont3 .text .text-img {
    font-size: 0.8rem;
  }
}

.section2 .white-bg .cont3 .text .t {
  position: relative;
  z-index: 1;
  margin-top: 0.6rem;
  padding-left: 0.6rem;
}

@media (max-width: 767px) {
  .section2 .white-bg .cont3 .text .t {
    background: #fff;
  }
}

@media (max-width: 767px) {
  .section2 .white-bg .cont3 .text .t {
    margin-top: 0.1rem;
  }
}

@media (max-width: 767px) {
  .section2 .white-bg .cont3 .text .t {
    padding-left: 0.3rem;
  }
}

@media (max-width: 767px) {
  .section2 .white-bg .cont3 .text .t {
    padding: 0.3rem;
  }
}

@media (max-width: 767px) {
  .section2 .white-bg .cont3 .text .t {
    padding-bottom: 0.6rem;
  }
}

.section2 .white-bg .cont3 .text .t::after {
  content: '';
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 99%;
  background-color: #fff;
  z-index: -1;
  display: none;
}

@media (max-width: 767px) {
  .section2 .white-bg .cont3 .text .t::after {
    display: block;
  }
}

.section2 .white-bg .cont3 .text .t p {
  line-height: 2;
  max-width: 5.9rem;
}

.section2 .white-bg .cont3 .text .t .common-link {
  position: absolute;
  bottom: 20%;
  right: 30%;
}

@media (max-width: 767px) {
  .section2 .white-bg .cont3 .text .t .common-link {
    bottom: 5%;
  }
}

@media (max-width: 767px) {
  .section2 .white-bg .cont3 .text .t .common-link {
    right: 0;
  }
}

.section2 .white-bg .cont4 {
  position: relative;
  justify-content: flex-start;
  background: url(../../assets/images/bg_img.jpg) repeat;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  padding-left: 0.6rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  margin-left: -0.6rem;
  width: 91%;
}

@media (max-width: 767px) {
  .section2 .white-bg .cont4 {
    align-items: flex-start;
  }
}

@media (max-width: 767px) {
  .section2 .white-bg .cont4 {
    flex-direction: column-reverse;
  }
}

@media (max-width: 767px) {
  .section2 .white-bg .cont4 {
    padding-left: 0.5rem;
  }
}

@media (max-width: 767px) {
  .section2 .white-bg .cont4 {
    padding-top: 0.8rem;
  }
}

@media (max-width: 767px) {
  .section2 .white-bg .cont4 {
    padding-bottom: 0.8rem;
  }
}

@media (max-width: 767px) {
  .section2 .white-bg .cont4 {
    margin-left: -6%;
  }
}

@media (max-width: 767px) {
  .section2 .white-bg .cont4 {
    width: 112%;
  }
}

.section2 .white-bg .cont4 .bg {
  height: 102%;
  position: absolute;
  top: 0;
  background: url(../../assets/images/line_bg.png) repeat-y;
  background-size: 100% auto;
  transform-origin: top;
  right: 0.3rem;
  width: 2rem;
}

@media (max-width: 1024px) {
  .section2 .white-bg .cont4 .bg {
    transform: scaleY(0);
  }
}

.section2 .white-bg .cont4 .left {
  flex-shrink: 0;
  position: relative;
  z-index: 3;
  margin-right: 0.6rem;
  width: 6.6rem;
}

@media (max-width: 767px) {
  .section2 .white-bg .cont4 .left {
    margin-top: 1rem;
  }
}

@media (max-width: 767px) {
  .section2 .white-bg .cont4 .left {
    margin-right: 0;
  }
}

@media (max-width: 767px) {
  .section2 .white-bg .cont4 .left {
    width: 100%;
  }
}

.section2 .white-bg .cont4 .left .t1 {
  color: #fff;
  line-height: 1.2;
  padding-left: 1rem;
  font-size: 0.52rem;
  margin-bottom: 0.5rem;
}

.section2 .white-bg .cont4 .left .t1 span {
  font-size: 0.8rem;
  margin-bottom: 0.1rem;
}

.section2 .white-bg .cont4 .left .t2 {
  line-height: 2;
  padding-left: 1rem;
  max-width: 5rem;
  margin-bottom: 2.5rem;
}

@media (max-width: 767px) {
  .section2 .white-bg .cont4 .left .t2 {
    margin-bottom: 0;
  }
}

.section2 .white-bg .cont4 .left .t3 {
  white-space: nowrap;
  margin-bottom: 0.6rem;
}

@media (max-width: 767px) {
  .section2 .white-bg .cont4 .left .t3 {
    right: 5%;
  }
}

@media (max-width: 767px) {
  .section2 .white-bg .cont4 .left .t3 {
    top: -30%;
  }
}

@media (max-width: 767px) {
  .section2 .white-bg .cont4 .left .t3 {
    position: absolute;
  }
}

.section2 .white-bg .cont4 .right {
  position: relative;
  z-index: 2;
}

.section2 .white-bg .cont4 .right .img1 {
  margin-left: 48%;
}

@media (max-width: 767px) {
  .section2 .white-bg .cont4 .right .img1 {
    margin-left: 16%;
  }
}

.section2 .white-bg .cont4 .right .img1 img {
  display: block;
  height: auto;
  width: 7rem;
}

@media (max-width: 767px) {
  .section2 .white-bg .cont4 .right .img1 img {
    width: 6rem;
  }
}

.section2 .white-bg .cont4 .right .img2 {
  margin-top: -20%;
}

.section2 .white-bg .cont4 .right .img2 img {
  display: block;
  height: auto;
  width: 5.3rem;
}

.section3 {
  background-image: linear-gradient(to top, #e7d7b9, #f5eedc);
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  padding-left: 12.5%;
  padding-right: 8.5%;
  padding-top: 1.3rem;
  padding-bottom: 4rem;
  display: flex;
}

@media (max-width: 1600px) {
  .section3 {
    padding-left: 11%;
  }
}

@media (max-width: 1366px) {
  .section3 {
    padding-left: 9.5%;
  }
}

@media (max-width: 1024px) {
  .section3 {
    padding-left: 8%;
  }
}

@media (max-width: 991px) {
  .section3 {
    padding-left: 6.5%;
  }
}

@media (max-width: 767px) {
  .section3 {
    padding-left: 5%;
  }
}

@media (max-width: 1600px) {
  .section3 {
    padding-right: 7.8%;
  }
}

@media (max-width: 1366px) {
  .section3 {
    padding-right: 7.1%;
  }
}

@media (max-width: 1024px) {
  .section3 {
    padding-right: 6.4%;
  }
}

@media (max-width: 991px) {
  .section3 {
    padding-right: 5.7%;
  }
}

@media (max-width: 767px) {
  .section3 {
    padding-right: 5%;
  }
}

@media (max-width: 767px) {
  .section3 {
    padding-top: 0;
  }
}

@media (max-width: 767px) {
  .section3 {
    padding-bottom: 1rem;
  }
}

@media (max-width: 767px) {
  .section3 {
    display: block;
  }
}

.section3 .left {
  position: relative;
  flex-shrink: 0;
  box-sizing: border-box;
  z-index: 1;
  width: 68%;
  padding-left: 0.25rem;
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .section3 .left {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .section3 .left {
    margin-bottom: 2rem;
  }
}

.section3 .left .bg {
  content: '';
  display: block;
  width: 150%;
  height: 70%;
  background-color: #f8f3e9;
  position: absolute;
  z-index: -1;
  top: 45%;
  right: 15%;
  transform-origin: top;
  transform: scale(1, 0);
  transition-delay: 2s;
  pointer-events: none;
  transition: transform 1500ms cubic-bezier(0.575, 0.005, 0.285, 1.005), opacity 1100ms cubic-bezier(0.575, 0.005, 0.285, 1.005);
}

@media (max-width: 767px) {
  .section3 .left .bg {
    display: none;
  }
}

.section3 .left .bg.is-inview {
  transform: scale(1, 1);
}

.section3 .left .img1 {
  position: relative;
  width: auto;
  margin-left: 0;
}

@media (max-width: 767px) {
  .section3 .left .img1 {
    height: 110vw;
  }
}

@media (max-width: 767px) {
  .section3 .left .img1 {
    width: calc(112% + .25rem);
  }
}

@media (max-width: 767px) {
  .section3 .left .img1 {
    margin-left: calc(-6% - .25rem);
  }
}

.section3 .left .img1 img {
  display: block;
  width: 100%;
  height: auto;
  position: static;
}

@media (max-width: 767px) {
  .section3 .left .img1 img {
    top: 50%;
  }
}

@media (max-width: 767px) {
  .section3 .left .img1 img {
    left: 50%;
  }
}

@media (max-width: 767px) {
  .section3 .left .img1 img {
    width: auto;
  }
}

@media (max-width: 767px) {
  .section3 .left .img1 img {
    height: 100%;
  }
}

@media (max-width: 767px) {
  .section3 .left .img1 img {
    transform: translate3d(-50%, -50%, 0);
  }
}

@media (max-width: 767px) {
  .section3 .left .img1 img {
    position: absolute;
  }
}

.section3 .left .img2 {
  position: absolute;
  bottom: 2%;
  left: -10%;
  font-size: 1.68rem;
}

@media (max-width: 767px) {
  .section3 .left .img2 {
    white-space: normal;
  }
}

@media (max-width: 767px) {
  .section3 .left .img2 {
    width: 100px;
  }
}

@media (max-width: 767px) {
  .section3 .left .img2 {
    left: -2%;
  }
}

@media (max-width: 767px) {
  .section3 .left .img2 {
    font-size: 1.2rem;
  }
}

.section3 .left .t {
  position: absolute;
  white-space: nowrap;
  left: 90%;
  bottom: -1%;
}

@media (max-width: 767px) {
  .section3 .left .t {
    left: 15%;
  }
}

@media (max-width: 767px) {
  .section3 .left .t {
    bottom: -19%;
  }
}

.section3 .right {
  flex-shrink: 0;
  width: 25%;
}

@media (max-width: 767px) {
  .section3 .right {
    width: 100%;
  }
}

.section3 .right .title {
  color: #fff;
  line-height: 1.1;
  font-size: 0.82rem;
  margin-bottom: 0.35rem;
}

.section3 .right .title > div:nth-child(2) span {
  transition-delay: .5s;
}

.section3 .right .t {
  line-height: 2;
}

.section4 {
  background-color: #f5eedd;
  padding-bottom: 0.1rem;
}

.section4 .cont1 {
  display: flex;
  justify-content: flex-end;
  position: relative;
  margin-bottom: 2rem;
}

@media (max-width: 767px) {
  .section4 .cont1 {
    margin-bottom: 1rem;
  }
}

.section4 .cont1 .text {
  position: absolute;
  z-index: 1;
  left: 12.7%;
  bottom: 5%;
}

@media (max-width: 767px) {
  .section4 .cont1 .text {
    left: 6%;
  }
}

.section4 .cont1 .text .img {
  display: block;
  font-size: 1.68rem;
  margin-bottom: 0.6rem;
}

@media (max-width: 767px) {
  .section4 .cont1 .text .img {
    font-size: 1rem;
  }
}

@media (max-width: 767px) {
  .section4 .cont1 .text .img {
    margin-bottom: 0.3rem;
  }
}

.section4 .cont1 .text .t {
  white-space: nowrap;
}

.section4 .cont1 .text .t .common-tag .tag {
  padding: 0.6rem 1.3rem;
}

@media (max-width: 767px) {
  .section4 .cont1 .text .t .common-tag .tag {
    padding: 0.3rem 0.4rem;
  }
}

.section4 .cont1 .pic_1 {
  flex-shrink: 0;
  width: 62.5%;
}

@media (max-width: 767px) {
  .section4 .cont1 .pic_1 {
    width: 100%;
  }
}

.section4 .cont1 .pic_1 img {
  display: block;
  width: 100%;
  height: auto;
}

.section4 .cont2 {
  padding-left: 21.8%;
  padding-right: 21.8%;
}

@media (max-width: 1024px) {
  .section4 .cont2 {
    padding-left: 18%;
  }
}

@media (max-width: 767px) {
  .section4 .cont2 {
    padding-left: 10%;
  }
}

@media (max-width: 1024px) {
  .section4 .cont2 {
    padding-right: 18%;
  }
}

@media (max-width: 767px) {
  .section4 .cont2 {
    padding-right: 10%;
  }
}

.section4 .cont2 ul li {
  position: relative;
  z-index: 1;
  margin-bottom: 2.9rem;
}

@media (max-width: 767px) {
  .section4 .cont2 ul li {
    margin-bottom: 1rem;
  }
}

.section4 .cont2 ul li:nth-child(even) .item-t {
  flex-direction: row-reverse;
}

.section4 .cont2 ul li:nth-child(even) .item-t .t {
  text-align: right;
  flex-direction: row-reverse;
}

.section4 .cont2 ul li:nth-child(even) .item-t .t .img {
  margin-right: 0;
  margin-left: 0.2rem;
}

.section4 .cont2 ul li:nth-child(even) .bg {
  right: auto;
  background-image: linear-gradient(to right, #fff, #fff, #fff, rgba(255, 255, 255, 0));
  transform-origin: left;
  left: -10%;
}

.section4 .cont2 ul li .item-img {
  margin-bottom: 0.3rem;
}

.section4 .cont2 ul li .item-img img {
  display: block;
  width: 100%;
  height: auto;
}

.section4 .cont2 ul li .item-t {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.section4 .cont2 ul li .item-t .t {
  display: flex;
  align-items: center;
}

.section4 .cont2 ul li .item-t .t .img {
  margin-right: 0.2rem;
}

.section4 .cont2 ul li .item-t .t .img img {
  display: block;
  width: auto;
  height: 0.6rem;
}

@media (max-width: 1024px) {
  .section4 .cont2 ul li .item-t .t .img img {
    height: 0.8rem;
  }
}

@media (max-width: 767px) {
  .section4 .cont2 ul li .item-t .t .img img {
    height: 0.8rem;
  }
}

.section4 .cont2 ul li .item-t .t .text {
  line-height: 1.4;
  font-size: 0.25rem;
}

@media (max-width: 1024px) {
  .section4 .cont2 ul li .item-t .t .text {
    font-size: 0.3rem;
  }
}

@media (max-width: 767px) {
  .section4 .cont2 ul li .item-t .t .text {
    font-size: 0.3rem;
  }
}

.section4 .cont2 ul li .item-t .t .text h2 {
  font-weight: bold;
}

.section4 .cont2 ul li .bg {
  width: 130%;
  height: 80%;
  position: absolute;
  z-index: -1;
  background-image: linear-gradient(to left, #fff, #fff, #fff, rgba(255, 255, 255, 0));
  transform-origin: right;
  transform: scale(0, 1);
  pointer-events: none;
  transition-delay: 2s;
  transition: transform 1500ms cubic-bezier(0.575, 0.005, 0.285, 1.005), opacity 1100ms cubic-bezier(0.575, 0.005, 0.285, 1.005);
  top: 30%;
  right: -10%;
}

.section4 .cont2 ul li .bg.is-inview {
  transform: scale(1, 1);
}

.section5 {
  overflow: hidden;
  position: relative;
  height: 100vh;
}

@media (max-width: 1024px) {
  .section5 {
    height: 50vw;
  }
}

@media (max-width: 767px) {
  .section5 {
    height: 60vw;
  }
}

.section5 .bg-img {
  position: absolute;
  left: 0;
  width: 100%;
  height: 108%;
  z-index: 0;
  background-position: center;
  background-size: cover;
  height: 108%;
  top: -8%;
}

@media (max-width: 1024px) {
  .section5 .bg-img {
    height: 100%;
  }
}

@media (max-width: 1024px) {
  .section5 .bg-img {
    top: 0%;
  }
}

.section5 .cont {
  position: absolute;
  z-index: 1;
  width: 100%;
  bottom: 0;
  text-align: center;
  padding-bottom: 0.6rem;
}

@media (max-width: 767px) {
  .section5 .cont {
    padding-bottom: 0.2rem;
  }
}

.section5 .cont .img1 {
  overflow: hidden;
  font-size: 1.68rem;
}

@media (max-width: 767px) {
  .section5 .cont .img1 {
    font-size: 1rem;
  }
}

.section5 .cont .img1 span {
  display: inline-block;
}

.section5 .cont .line-c {
  margin-top: 0.35rem;
  margin-bottom: 0.5rem;
}

@media (max-width: 767px) {
  .section5 .cont .line-c {
    margin-top: 0.2rem;
  }
}

@media (max-width: 767px) {
  .section5 .cont .line-c {
    margin-bottom: 0.2rem;
  }
}

.section5 .cont .line-c.is-inview .line {
  opacity: 1;
  transform: scale(1, 1);
}

.section5 .cont .line-c .line {
  position: relative;
  margin: 0 auto;
  transform-origin: center;
  transform: scale(0, 1);
  opacity: 0;
  width: 87%;
  height: 0.14rem;
}

.section5 .cont .line-c .line::before, .section5 .cont .line-c .line::after {
  content: '';
  display: block;
  width: 100%;
  position: absolute;
  left: 0;
  background-color: #f5eedd;
}

.section5 .cont .line-c .line::before {
  top: 0;
  height: 0.04rem;
}

.section5 .cont .line-c .line::after {
  bottom: 0;
  height: 0.02rem;
}

.section5 .cont .img2 img {
  height: auto;
  width: 0.68rem;
}

.section6 {
  background-color: #295659;
  align-items: center;
  justify-content: space-between;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 8.8%;
  padding-right: 8.6%;
  display: flex;
}

@media (max-width: 767px) {
  .section6 {
    padding-top: 1rem;
  }
}

@media (max-width: 767px) {
  .section6 {
    padding-bottom: 1rem;
  }
}

@media (max-width: 1600px) {
  .section6 {
    padding-left: 8.04%;
  }
}

@media (max-width: 1366px) {
  .section6 {
    padding-left: 7.28%;
  }
}

@media (max-width: 1024px) {
  .section6 {
    padding-left: 6.52%;
  }
}

@media (max-width: 991px) {
  .section6 {
    padding-left: 5.76%;
  }
}

@media (max-width: 767px) {
  .section6 {
    padding-left: 5%;
  }
}

@media (max-width: 1600px) {
  .section6 {
    padding-right: 7.88%;
  }
}

@media (max-width: 1366px) {
  .section6 {
    padding-right: 7.16%;
  }
}

@media (max-width: 1024px) {
  .section6 {
    padding-right: 6.44%;
  }
}

@media (max-width: 991px) {
  .section6 {
    padding-right: 5.72%;
  }
}

@media (max-width: 767px) {
  .section6 {
    padding-right: 5%;
  }
}

@media (max-width: 767px) {
  .section6 {
    display: block;
  }
}

.section6 .left {
  flex-shrink: 0;
  position: relative;
  width: 58.8%;
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .section6 .left {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .section6 .left {
    margin-bottom: 0.5rem;
  }
}

.section6 .left .img1 img {
  display: block;
  width: 100%;
  height: auto;
}

.section6 .left .img2 {
  position: absolute;
  bottom: 8%;
  left: 78%;
  font-size: 1.15rem;
}

@media (max-width: 1024px) {
  .section6 .left .img2 {
    bottom: 5%;
  }
}

@media (max-width: 991px) {
  .section6 .left .img2 {
    bottom: 2%;
  }
}

@media (max-width: 767px) {
  .section6 .left .img2 {
    bottom: 44%;
  }
}

@media (max-width: 767px) {
  .section6 .left .img2 {
    left: 50%;
  }
}

@media (max-width: 767px) {
  .section6 .left .img2 {
    transform: translate3d(-50%, -50%, 0);
  }
}

@media (max-width: 767px) {
  .section6 .left .img2 {
    font-size: 0.8rem;
  }
}

.section6 .right {
  flex-shrink: 0;
  position: relative;
  width: 34.6%;
}

@media (max-width: 767px) {
  .section6 .right {
    width: 100%;
  }
}

.section6 .right .title {
  line-height: 1.4;
  color: #fff;
  font-size: 0.45rem;
  margin-bottom: 0.35rem;
}

@media (max-width: 767px) {
  .section6 .right .title {
    text-align: center;
  }
}

.section6 .right .title > div:nth-child(2) span {
  transition-delay: .5s;
}

.section6 .right .t {
  color: #fff;
  line-height: 2;
}

@media (max-width: 767px) {
  .section6 .right .t {
    font-weight: lighter;
  }
}

@media (max-width: 767px) {
  .section6 .right .t {
    font-size: 0.26rem;
  }
}

.section6 .right .link {
  position: static;
}

@media (max-width: 767px) {
  .section6 .right .link {
    transform: translateX(-50%);
  }
}

@media (max-width: 767px) {
  .section6 .right .link {
    left: 50%;
  }
}

@media (max-width: 767px) {
  .section6 .right .link {
    top: -260%;
  }
}

@media (max-width: 767px) {
  .section6 .right .link {
    position: absolute;
  }
}

@media (max-width: 767px) {
  .section6 .right .link {
    text-align: center;
  }
}

.section6 .right .link a {
  display: inline-block;
  background-color: #efe3c6;
  color: #ae9f7c;
  border-radius: 15px;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  font-size: 0.24rem;
  margin-top: 0.4rem;
}

@media (max-width: 1600px) {
  .section6 .right .link a {
    border-radius: 13px;
  }
}

@media (max-width: 1366px) {
  .section6 .right .link a {
    border-radius: 11px;
  }
}

@media (max-width: 1024px) {
  .section6 .right .link a {
    border-radius: 9px;
  }
}

@media (max-width: 991px) {
  .section6 .right .link a {
    border-radius: 7px;
  }
}

@media (max-width: 767px) {
  .section6 .right .link a {
    border-radius: 5px;
  }
}
