@import "../../assets/styles/utils";

.section1{
  background-image: linear-gradient(to bottom,#fff,#f5eedd);
  overflow: hidden;
  @include res(padding-left,10%, 5 / 10);
  @include res(padding-right,10%, 5 / 10);
  @include res(padding-top,.7rem,(md:1.2rem,xs:.7rem));
  @include res(padding-bottom,1.9rem,(xs:1rem));
  .t-img{
    text-align: center;
    white-space: nowrap;
    @include res(font-size,1.6rem,(xs:1.2rem));
    .img{
      overflow: hidden;
      &.is-inview{
        img{
          transform: translateY(0);
        }
      }
      img{
        display: block;
        max-width: 90%;
        height: auto;
        transform: translateY(100%);
        margin: 0 auto;
        @include res(width,7rem);
      }
    }
    .t{
      overflow: hidden;
      color: #efe3c6;
      // @include res(margin-top,.1rem);
      @include res(font-weight,lighter,(md:normal));
      @include res(margin-bottom,.2rem);
      @include res(font-size,.28rem,(xs:.34rem));
      &.is-inview{
        span{
          transform: translateY(0);
        }
      }
      span{
        display: block;
        transform: translateY(100%);
      }
    }
  }
  .video-c{
    overflow: hidden;
    position: relative;
    // @include res(height,43vw,(md:100vw,xs:120vw));
    .video{
      width: 100%;
      height: auto;
      background-position: center;
      background-size: cover;
    }
    .play{
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      border: solid rgba(255,255,255,.3);
      position: absolute;
      z-index: 2;
      transition: all .5s;
      cursor: pointer;
      z-index: 2;
      @include res(top,calc(50% - 1.6rem),(xs:calc(50% - .7rem)));
      @include res(left,calc(50% - 1.6rem),(xs:calc(50% - .7rem)));
      @include res(border-width,.1rem,(xs:.05rem));
      @include res(width,3.2rem,(xs:1.4rem));
      @include res(height,3.2rem,(xs:1.4rem));
      .iconfont{
        line-height: 1;
        color: rgba(255,255,255,.3);
        @include res(font-size,2rem,(xs:.8rem));
      }
      &.fadeOut{
        opacity: 0;
        visibility: hidden;
      }
    }
  }
}
.section2{
  background: $color-main-bg;
  @include res(padding-left,11.2%, 5 / 11.2);
  .cont1{
    @include res(margin-bottom,.6rem);
    h2{
      line-height: 1.3;
      @include res(font-size,.57rem,(xs:.44rem));
      @include res(margin-bottom,.3rem);
      >div{
        &:nth-child(2) span{
          transition-delay: .5s;
        }
      }
      +div span{
        transition-delay: 1s;
        @include res(font-size,.4rem,(xs:.4rem));
      }
    }
  }
  .white-bg{
    background-color: #fff;
    @include res(padding-right,9.7%, 5 / 9.7);
    @include res(padding-bottom,1.8rem,(xs:0rem));
    .cont2{
      align-items: flex-start;
      justify-content: space-between;
      @include res(display,flex,(xs:block));
      .left{
        flex-shrink: 0;
        box-sizing: border-box;
        @include res(padding-top,1.2rem,(xs:.6rem));
        @include res(padding-left,.9rem,(xs:.3rem));
        @include res(width,42%,(xs:100%));
        .t{
          line-height: 2;
          
          p{
            @include res(margin-bottom,.4rem);
          }
        }
        .t-img{
          position: relative;
          @include res(margin-top,1rem);
          @include res(width,3.06rem);
          .img1{
            display: block;
            height: auto;
            width: 100%;
          }
          .link{
            position: absolute;
            left: 100%;
            bottom: 0;
            .flourish{
              white-space: nowrap;
              text-shadow: none;
              display: block;
              @include res(font-size,.38rem,(xs:.44rem));
              @include res(margin-bottom,.25rem);
            }
          }
        }
      }
      .right{
        flex-shrink: 0;
        background-color: #f5eedd;
        position: relative;
        z-index: 1;
        @include res(margin-top,-10%,(xs:1.2rem));
        @include res(padding-bottom,1.2rem,(xs:.8rem));
        @include res(width,44%,(xs:80%));
        &::after{
          content: '';
          width: 200%;
          height: 100%;
          background-color: #f5eedd;
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1;
          @include res(display,none,(xs:block));
        }
        .img1{
          display: block;
          width: 100%;
          height: auto;
          margin-top: -10%;
          margin-left: 30%;
        }
        .img2{
          display: block;
          width: 90%;
          height: auto;
          position: absolute;
          @include res(top,8%,(xs:12%));
          @include res(left,-20%,(xs:0));
        }
      }
    }
    .cont3{
      position: relative;
      z-index: 1;
      @include res(margin-top,1.5rem,(xs:0rem));
      @include res(padding-top,0,(xs:.5rem));
      @include res(padding-bottom,1.2rem,(xs:1rem));
      @include res(padding-left,10%, 5 / 10);
      &::after{
        content: '';
        width: 200%;
        height: 100%;
        background-color: #f5eedd;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        @include res(display,none,(xs:block));
      }
      .pic_1{
        @include res(width,null,(xs:110%));
        @include res(margin-left,null,(xs:-5%));
        @include res(padding-right,15%, 0 / 15);
        img{
          display: block;
          width: 100%;
          height: auto;
        }
      }
      .text{
        position: relative;
        @include res(margin-top,-1rem);
        .common-tag{
          @include res(margin-left,-.6rem,(xs:0));
        }
        .text-img{
          position: absolute;
          right: 0;
          @include res(top,-20%,(xs:-80%));
          @include res(font-size,1.28rem,(xs:.8rem));
        }
        .t{
          position: relative;
          z-index: 1;
          @include res(background,null,(xs:#fff));
          @include res(margin-top,.6rem,(xs:.1rem));
          @include res(padding-left,.6rem,(xs:.3rem));
          @include res(padding,null,(xs:.3rem));
          @include res(padding-bottom,null,(xs:.6rem));
          &::after{
            content: '';
            width: 50%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 99%;
            background-color: #fff;
            z-index: -1;
            @include res(display,none,(xs:block));
          }
          p{
            line-height: 2;
            @include res(max-width,5.9rem);
          }
          .common-link{
            position: absolute;
            @include res(bottom,20%,(xs:5%));
            @include res(right,30%,(xs:0));
          }
        }
      }
    }
    .cont4{
      position: relative;
      justify-content: flex-start;
      background: url(../../assets/images/bg_img.jpg) repeat;
      @include res(display,flex);
      @include res(align-items,flex-end,(xs:flex-start));
      @include res(flex-direction,row,(xs:column-reverse));
      @include res(padding-left,.6rem,(xs:.5rem));
      @include res(padding-top,1.5rem,(xs:.8rem));
      @include res(padding-bottom,1.5rem,(xs:.8rem));
      @include res(margin-left,-.6rem,(xs:-6%));
      @include res(width,91%,(xs:112%));
      .bg{
        height: 102%;
        position: absolute;
        top: 0;
        background: url(../../assets/images/line_bg.png) repeat-y;
        background-size: 100% auto;
        transform-origin: top;
        @include res(transform,null,(md:scaleY(0)));
        @include res(right,.3rem);
        @include res(width,2rem);
      }
      .left{
        flex-shrink: 0;
        position: relative;
        z-index: 3;
        @include res(margin-top,null,(xs:1rem));
        @include res(margin-right,.6rem,(xs:0));
        @include res(width,6.6rem,(xs:100%));
        .t1{
          color: #fff;
          line-height: 1.2;
          @include res(padding-left,1rem);
          @include res(font-size,.52rem);
          @include res(margin-bottom,.5rem);
          span{
            @include res(font-size,.8rem);
            @include res(margin-bottom,.1rem);
          }
        }
        .t2{
          line-height: 2;
          @include res(padding-left,1rem);
          @include res(max-width,5rem);
          @include res(margin-bottom,2.5rem,(xs:0));
        }
        .t3{
          white-space: nowrap;
          @include res(right,null,(xs:5%));
          @include res(top,null,(xs:-30%));
          @include res(position,null,(xs:absolute));
          @include res(margin-bottom,.6rem);
        }
      }
      .right{
        position: relative;
        z-index: 2;
        .img1{
          @include res(margin-left,48%,(xs:16%));
          img{
            display: block;
            height: auto;
            @include res(width,7rem,(xs:6rem));
          }
        }
        .img2{
          @include res(margin-top,-20%);
          img{
            display: block;
            height: auto;
            @include res(width,5.3rem);
          }
        }
      }
    }
  }
}

.section3{
  background-image: linear-gradient(to top,#e7d7b9,#f5eedc);
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  @include res(padding-left,12.5%, 5 / 12.5);
  @include res(padding-right,8.5%, 5 / 8.5);
  @include res(padding-top,1.3rem,(xs:0));
  @include res(padding-bottom,4rem,(xs:1rem));
  @include res(display,flex,(xs:block));
  .left{
    position: relative;
    flex-shrink: 0;
    box-sizing: border-box;
    z-index: 1;
    @include res(width,68%,(xs:100%));
    @include res(padding-left,.25rem);
    @include res(margin-bottom,0,(xs:2rem));
    .bg{
      content: '';
      @include res(display,block,(xs:none));
      width: 150%;
      height: 70%;
      background-color: #f8f3e9;
      position: absolute;
      z-index: -1;
      top: 45%;
      right: 15%;
      transform-origin: top;
      transform: scale(1,0);
      transition-delay: 2s;
      pointer-events: none;
      transition: transform 1500ms cubic-bezier(0.575, 0.005, 0.285, 1.005),
                  opacity 1100ms cubic-bezier(0.575, 0.005, 0.285, 1.005);
      &.is-inview{
        transform: scale(1,1);
      }
      
    }
    .img1{
      position: relative;
      @include res(height,null,(xs:110vw));
      @include res(width,auto,(xs:calc(112% + .25rem)));
      @include res(margin-left,0,(xs:calc(-6% - .25rem)));
      img{
        display: block;
        @include res(top,null,(xs:50%));
        @include res(left,null,(xs:50%));
        @include res(width,100%,(xs:auto));
        @include res(height,auto,(xs:100%));
        @include res(transform,null,(xs:translate3d(-50%,-50%,0)));
        @include res(position,static,(xs:absolute));
      }
    }
    .img2{
      position: absolute;
      bottom: 2%;
      @include res(white-space,null,(xs:normal));
      @include res(width,null,(xs:100px));
      @include res(left,-10%,(xs:-2%));
      @include res(font-size,1.68rem,(xs:1.2rem));
    }
    .t{
      position: absolute;
      white-space: nowrap;
      @include res(left,90%,(xs:15%));
      @include res(bottom,-1%,(xs:-19%));
    }
  }
  .right{
    flex-shrink: 0;
    @include res(width,25%,(xs:100%));
    .title{
      color: #fff;
      line-height: 1.1;
      @include res(font-size,.82rem);
      @include res(margin-bottom,.35rem);
      >div:nth-child(2) span{
        transition-delay: .5s;
      }
    }
    .t{
      line-height: 2;
      
    }
  }
}

.section4{
  background-color: $color-main-bg;
  @include res(padding-bottom,.1rem);
  .cont1{
    display: flex;
    justify-content: flex-end;
    position: relative;
    @include res(margin-bottom,2rem,(xs:1rem));
    .text{
      position: absolute;
      z-index: 1;
      @include res(left,12.7%,(xs:6%));
      @include res(bottom,5%);
      .img{
        display: block;
        @include res(font-size,1.68rem,(xs:1rem));
        @include res(margin-bottom,.6rem,(xs:.3rem));
      }
      .t{
        white-space: nowrap;
        .common-tag .tag{
          @include res(padding,.6rem 1.3rem,(xs:.3rem .4rem));
        }
      }
    }
    .pic_1{
      flex-shrink: 0;
      @include res(width,62.5%,(xs:100%));
      img{
        display: block;
        width: 100%;
        height: auto;
      }
    }
  }
  .cont2{
    @include res(padding-left,21.8%,(md:18%,xs:10%));
    @include res(padding-right,21.8%,(md:18%,xs:10%));
    ul li{
      position: relative;
      z-index: 1;
      @include res(margin-bottom,2.9rem,(xs:1rem));
      &:nth-child(even){
        .item-t{
          flex-direction: row-reverse;
          .t{
            text-align: right;
            flex-direction: row-reverse;
            .img{
              @include res(margin-right,0);
              @include res(margin-left,.2rem);
            }
          }
        }
        .bg{
          right: auto;
          background-image: linear-gradient(to right,#fff,#fff,#fff,rgba(255,255,255,.0));
          transform-origin: left;
          @include res(left,-10%);
        }
      }
      .item-img{
        @include res(margin-bottom,.3rem);
        img{
          display: block;
          width: 100%;
          height: auto;
        }
      }
      .item-t{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .t{
          display: flex;
          align-items: center;
          .img{
            @include res(margin-right,.2rem);
            img{
              display: block;
              width: auto;
              @include res(height,.6rem,(md:.8rem,xs:.8rem));
            }
          }
          .text{
            line-height: 1.4;
            @include res(font-size,.25rem,(md:.3rem,xs:.3rem));
            h2{
              font-weight: bold;
            }
          }
        }
      }
      .bg{
        width: 130%;
        height: 80%;
        position: absolute;
        z-index: -1;
        background-image: linear-gradient(to left,#fff,#fff,#fff,rgba(255,255,255,.0));
        transform-origin: right;
        transform: scale(0,1);
        pointer-events: none;
        transition-delay: 2s;
        transition: transform 1500ms cubic-bezier(0.575, 0.005, 0.285, 1.005),
                  opacity 1100ms cubic-bezier(0.575, 0.005, 0.285, 1.005);
        @include res(top,30%);
        @include res(right,-10%);
        &.is-inview{
          transform: scale(1,1);
        }
      }
    }
  }
}

.section5{
  overflow: hidden;
  position: relative;
  @include res(height,100vh,(md:50vw,xs:60vw));
  .bg-img{
    position: absolute;
    left: 0;
    width: 100%;
    height: 108%;
    z-index: 0;
    background-position: center;
    background-size: cover;
    @include res(height,108%,(md:100%));
    @include res(top,-8%,(md:0%));
  }
  .cont{
    position: absolute;
    z-index: 1;
    width: 100%;
    bottom: 0;
    text-align: center;
    @include res(padding-bottom,.6rem,(xs:.2rem));
    .img1{
      overflow: hidden;
      @include res(font-size,1.68rem,(xs:1rem));
      span{
        display: inline-block;
      }
    }
    .line-c{
      @include res(margin-top,.35rem,(xs:.2rem));
      @include res(margin-bottom,.5rem,(xs:.2rem));
      &.is-inview{
        .line{
          opacity: 1;
          transform: scale(1,1);
        }
      }
      .line{
        position: relative;
        margin: 0 auto;
        transform-origin: center;
        transform: scale(0,1);
        opacity: 0;
        @include res(width,87%);
        @include res(height,.14rem);
        &::before,&::after{
          content: '';
          display: block;
          width: 100%;
          position: absolute;
          left: 0;
          background-color: #f5eedd;
        }
        &::before{
          top: 0;
          @include res(height,.04rem);
        }
        &::after{
          bottom: 0;
          @include res(height,.02rem);
        }
      }
    }
    .img2{
      img{
        height: auto;
        @include res(width,.68rem);
      }
    }
  }
}

.section6{
  background-color: $color-main;
  align-items: center;
  justify-content: space-between;
  @include res(padding-top,2rem,(xs:1rem));
  @include res(padding-bottom,2rem,(xs:1rem));
  @include res(padding-left,8.8%, 5 / 8.8);
  @include res(padding-right,8.6%, 5 / 8.6);
  @include res(display,flex,(xs:block));
  .left{
    flex-shrink: 0;
    position: relative;
    @include res(width,58.8%,(xs:100%));
    @include res(margin-bottom,0,(xs:.5rem));
    .img1{
      img{
        display: block;
        width: 100%;
        height: auto;
      }
    }
    .img2{
      position: absolute;
      @include res(bottom,8%,(md:5%,sm:2%,xs:44%));
      @include res(left,78%,(xs:50%));
      @include res(transform,null,(xs:translate3d(-50%,-50%,0)));
      @include res(font-size,1.15rem,(xs:.8rem));
    }
  }
  .right{
    flex-shrink: 0;
    position: relative;
    @include res(width,34.6%,(xs:100%));
    .title{
      line-height: 1.4;
      color: #fff;
      @include res(text-align,null,(xs:center));
      @include res(font-size,.45rem);
      @include res(margin-bottom,.35rem);
      >div{
        &:nth-child(2){
          span{
            transition-delay: .5s;
          }
        }
      }
    }
    .t{
      color: #fff;
      line-height: 2;
      @include res(font-weight,null,(xs:lighter));
      @include res(font-size,null,(xs:.26rem));
    }
    .link{
      @include res(transform,null,(xs:translateX(-50%)));
      @include res(left,null,(xs:50%));
      @include res(top,null,(xs:-260%));
      @include res(position,static,(xs:absolute));
      @include res(text-align,null,(xs:center));
      a{
        display: inline-block;
        background-color: #efe3c6;
        color: #ae9f7c;
        @include res(border-radius,15px, 5 / 15);
        @include res(padding-left,.8rem);
        @include res(padding-right,.8rem);
        @include res(padding-top,.1rem);
        @include res(padding-bottom,.1rem);
        @include res(font-size,.24rem);
        @include res(margin-top,.4rem);
      }
    }
  }
}